// Basic libraries
import React, { Component } from "react"
import PropTypes from "prop-types"
import { Link, navigate } from "gatsby"
import moment from "moment/moment"

// Used components
import Navigation from "../../../components/navigation"
import Content from "../../../components/content"
import Seo from "../../../components/seo"
import DiagramsTabs from "../../../components/DiagramsTabs"

// Images & Styling
import { BackButton } from "../../../styles/buttons"
import { DiagramsLayout } from "../../../styles/common"
import ArrowIcon from "../../../lib/icons/Arrow"

// Fetching data
import { useGraphqlClientStore } from "../../../../store"
import { gql } from "graphql-request"

// Helpers
import { isJWTExpired } from "../../../services/helpers"

// Browser check
const isBrowser = typeof window !== "undefined"

// Token Access
const jwt = {
  jwtRequ: isBrowser ? window.localStorage.getItem("jwt_teacher") : null,
  checkPermission() {
    if ((!this.jwtRequ || isJWTExpired(this.jwtRequ)) && isBrowser)
      navigate("./")
    else if (this.jwtRequ)
      // graphqlClient.setHeader("authorization", `Bearer ${this.jwtRequ}`)
      useGraphqlClientStore.getState().setClientAuthHeader(this.jwtRequ)
  },
}

// GraphQL-Queries
const CLASS_BY_ID_QUERY = gql`
  query ($id: UUID!) {
    classById(id: $id) {
      name
      studentsByClass {
        nodes {
          id
          name
          alias
          mistakePrognosis
          dailyTarget
          inputSessionsByStudent {
            nodes {
              id
              word
              correct
              attempt
              input
              startTime
              durationMs
            }
          }
          uiActivitiesByStudentId {
            nodes {
              button
              page
              timestamp
              sessionId
              event
            }
          }
        }
      }
    }
  }
`

let dashboardData = {
  general: {
    class: "",
    student: "",
    isWeekend: false,
  },
  overview: {
    dailyTarget: {
      class: {
        studentsCount: 0,
        targetAchievement: 0,
      },
      student: {
        writtenWords: 0,
        targetWords: 0,
      },
    },
    mistakeCategory: {
      class: { mistake: [], safety: [] },
      student: { mistake: [], safety: [] },
    },
    wordArea: {
      writtenWords: [],
      correctWords: [],
      firstAttemptWords: [],
    },
    speed: {
      word: [],
      keyboard: [],
    },
    tasks: {
      student: [0, 0, 0, 0, 0],
      class: [0, 0, 0, 0, 0],
    },
  },
}

// component
class TeacherDashboardDiagramsPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      window: {
        width: 0,
        height: 0,
      },
      class: {
        id: isBrowser
          ? new URLSearchParams(window.location.search).get("classId")
          : null,
        name: "",
        rawData: [],
        studentsCount: 0,
        dailyTargetAchieved: 0,
      },
      student: {
        id: isBrowser
          ? new URLSearchParams(window.location.search).get("studentId")
          : null,
        name: "",
        rawData: {},
        dailyTargetData: {
          real: 0,
          target: 0,
        },
      },
    }
    this.handleResize = this.handleResize.bind(this)
  }

  handleResize = () => {
    this.setState({
      window: {
        width: window.innerWidth,
        height: window.innerHeight,
      },
    })
  }

  componentDidMount() {
    if (isBrowser) {
      this.setState(
        {
          window: {
            width: window.innerWidth,
            height: window.innerHeight,
          },
        },
        () => window.addEventListener("resize", this.handleResize)
      )
      this.setData()
    }
  }

  componentWillUnmount() {
    if (isBrowser) {
      window.addEventListener("resize", this.handleResize)
    }
  }

  setData = async () => {
    const { graphqlClient } = useGraphqlClientStore.getState()
    const classId = this.state.class.id
    const classData = await graphqlClient.request(CLASS_BY_ID_QUERY, {
      id: classId,
    })
    const className = classData.classById.name
    const data = classData.classById.studentsByClass.nodes
    this.setState(
      {
        class: {
          // id: classId,
          name: className,
          rawData: data,
        },
      },
      () => {
        this.setStudentRawData()
        this.forceUpdate()
      }
    )
  }

  setStudentRawData = () => {
    const studentId = this.state.student.id
    const rawData = this.state.class.rawData
    const className = this.state.class.name

    let studentName = ""
    let studentRawData = {}

    rawData.forEach((student) => {
      if (student["id"] == studentId) {
        studentRawData = student
        if (student["name"] != "") {
          studentName = student["name"]
        }
      }
    })

    this.setState(
      {
        student: {
          name: studentName,
          rawData: studentRawData,
        },
      },
      () => {
        this.prepareDailyTarget(studentId, rawData, studentName, className)
        this.prepareMistakes(studentId, rawData, studentName, className)
        this.prepareWords(studentRawData)
        this.prepareSpeed(studentRawData)
        this.prepareTasksByClass(rawData)
        dashboardData.overview.tasks.student =
          this.prepareTasksByStudent(studentRawData)

        this.forceUpdate()
      }
    )
  }

  prepareTasksByClass = (rawData) => {
    const classArr = []
    rawData.forEach((student) => {
      classArr.push(this.prepareTasksByStudent(student))
    })
    const classArrSum = [0, 0, 0, 0, 0]
    classArr.forEach((student) => {
      student.forEach((task, idx) => {
        classArrSum[idx] += task
      })
    })
    const tasksArr = [0, 0, 0, 0, 0]

    classArrSum.forEach((sum, idx) => (tasksArr[idx] = (sum / 5).toFixed(0)))
    dashboardData.overview.tasks.class = tasksArr.map(Number)
  }

  prepareTasksByStudent = (studentRawData) => {
    const uiActivities = studentRawData.uiActivitiesByStudentId.nodes
    // 1. HOME-PLANET:
    const homePlanetTasks = uiActivities.filter(
      (activity) => activity.page == "/student/profile/space/home_planet"
    )
    homePlanetTasks.sort(
      (a, b) => parseInt(b.timestamp) - parseInt(a.timestamp)
    )

    const homePlanetTimesInSecArr = []
    homePlanetTasks.forEach((task, idx) => {
      if (
        homePlanetTasks[idx + 1] &&
        task.event === "Seite verlassen" &&
        homePlanetTasks[idx + 1].event === "Seite besucht"
      ) {
        const endDate = new Date()
        endDate.setTime(task.timestamp)
        const startDate = new Date()
        startDate.setTime(homePlanetTasks[idx + 1].timestamp)
        const sec = ((endDate.getTime() - startDate.getTime()) / 1000).toFixed(
          0
        )
        homePlanetTimesInSecArr.push(sec)
      }
    })

    const homePlanetTaskSum = homePlanetTimesInSecArr
      .map(Number)
      .reduce((pv, cv) => pv + cv, 0)

    // 2. GAMES
    const gameTasks = uiActivities.filter(
      (activity) =>
        activity.page == "/student/profile/space/memory" ||
        activity.page == "/student/profile/space/letter_monster" ||
        activity.page == "/student/profile/space/word_monster" ||
        activity.page == "/student/profile/space/racer" ||
        activity.page == "/student/profile/space/iceSkater"
    )
    gameTasks.sort((a, b) => parseInt(b.timestamp) - parseInt(a.timestamp))

    const gameTimesInSecArr = []
    gameTasks.forEach((task, idx) => {
      if (
        gameTasks[idx + 1] &&
        task.page === gameTasks[idx + 1].page &&
        task.event === "Seite verlassen" &&
        gameTasks[idx + 1].event === "Seite besucht"
      ) {
        const endDate = new Date()
        endDate.setTime(task.timestamp)
        const startDate = new Date()
        startDate.setTime(gameTasks[idx + 1].timestamp)
        const sec = ((endDate.getTime() - startDate.getTime()) / 1000).toFixed(
          0
        )
        gameTimesInSecArr.push(sec)
      }
    })

    const gameTaskSum = gameTimesInSecArr
      .map(Number)
      .reduce((pv, cv) => pv + cv, 0)

    // 3. BASIS-TASK
    const basisTasks = uiActivities.filter(
      (activity) =>
        activity.page == "/exercise" &&
        (activity.event == "Seite besucht" ||
          activity.event == "Seite verlassen")
    )
    basisTasks.sort((a, b) => parseInt(b.timestamp) - parseInt(a.timestamp))

    const basisTimesInSecArr = []
    basisTasks.forEach((task, idx) => {
      if (basisTasks[idx + 1] && task.event === "Seite verlassen") {
        const endDate = new Date()
        endDate.setTime(task.timestamp)
        const startDate = new Date()
        startDate.setTime(basisTasks[idx + 1].timestamp)
        const sec = ((endDate.getTime() - startDate.getTime()) / 1000).toFixed(
          0
        )
        basisTimesInSecArr.push(sec)
      }
    })

    const basisTaskSum = basisTimesInSecArr
      .map(Number)
      .reduce((pv, cv) => pv + cv, 0)

    // 4. OTHER
    const otherTasks = uiActivities.filter(
      (activity) =>
        activity.event == "Seite besucht" || activity.event == "Seite verlassen"
    )
    otherTasks.sort((a, b) => parseInt(b.timestamp) - parseInt(a.timestamp))

    const otherTimesInSecArr = []
    otherTasks.forEach((task, idx) => {
      if (otherTasks[idx + 1] && task.event === "Seite verlassen") {
        const endDate = new Date()
        endDate.setTime(task.timestamp)
        const startDate = new Date()
        startDate.setTime(otherTasks[idx + 1].timestamp)
        const sec = ((endDate.getTime() - startDate.getTime()) / 1000).toFixed(
          0
        )
        otherTimesInSecArr.push(sec)
      }
    })

    const otherTaskSum =
      otherTimesInSecArr.map(Number).reduce((pv, cv) => pv + cv, 0) -
      basisTaskSum -
      homePlanetTaskSum -
      gameTaskSum

    const tasksArr = [
      basisTaskSum,
      gameTaskSum,
      homePlanetTaskSum,
      0, // Dashboard
      otherTaskSum,
    ]
    return tasksArr
  }

  prepareDailyTarget = (studentId, rawData, studentName, className) => {
    let studentsCount = 0
    const currentDateAndTime = new Date()
    const currentWeekDay = currentDateAndTime.getDay()
    const currentYear = currentDateAndTime.toLocaleString("default", {
      year: "numeric",
    })
    const currentMonth = currentDateAndTime.toLocaleString("default", {
      month: "2-digit",
    })
    const currentDay = currentDateAndTime.toLocaleString("default", {
      day: "2-digit",
    })
    const currentDate = currentYear + currentMonth + currentDay
    let classAchievement = 0
    let studentTargetLearnedWords = 0
    let studentRealLearnedWords = 0

    if (currentWeekDay >= 1 && currentWeekDay <= 5) {
      rawData.forEach((studentRawData) => {
        studentsCount += 1
        let targetLearnedWords = studentRawData["dailyTarget"]
        let realLearnedWords = 0
        const inputSessions = studentRawData.inputSessionsByStudent.nodes.sort(
          (a, b) => a.startTime - b.startTime
        )
        inputSessions.forEach((s) => {
          const sDateObj = new Date()
          sDateObj.setTime(s.startTime)

          const year = sDateObj.toLocaleString("default", {
            year: "numeric",
          })
          const month = sDateObj.toLocaleString("default", {
            month: "2-digit",
          })
          const day = sDateObj.toLocaleString("default", {
            day: "2-digit",
          })
          const date = year + month + day

          if (date == currentDate) {
            if (s.correct) {
              realLearnedWords += 1
            }
          }
        })

        if (studentRawData.id == studentId) {
          studentTargetLearnedWords = targetLearnedWords
          studentRealLearnedWords = realLearnedWords
        }
        if (realLearnedWords >= targetLearnedWords) {
          classAchievement += 1
        }
      })
    } else {
      dashboardData.general.isWeekend = true
    }
    this.setState(
      {
        class: {
          dailyTargetAchieved: classAchievement,
          studentsCount: studentsCount,
        },
        student: {
          dailyTargetData: {
            real: studentRealLearnedWords,
            target: studentTargetLearnedWords,
          },
        },
      },
      () => {
        this.forceUpdate()
        this.setDashboardData(studentName, className)
      }
    )
  }

  prepareMistakes = (studentId, rawData, studentName, className) => {
    const allMistakeNumbers = [
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
    ]
    const allCategoryNumbers = [
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
    ]
    const studentMistakeList = []
    const studentCategoryList = []

    const averageMistakeData = []
    const averageCategoryData = []
    let studentMistakeData = []
    let studentCategoryData = []

    rawData.forEach((student) => {
      const studentsMistakes = student.mistakePrognosis
      const maxStudentMistake = Math.max.apply(Math, studentsMistakes)
      const minStudentMistake = Math.min.apply(Math, studentsMistakes)
      const slowStocStudentCategorySkill = []
      const slowStocStudentMistake = []
      if (studentsMistakes) {
        studentsMistakes.forEach((mistake) => {
          const slowStocMistake = (
            ((mistake - minStudentMistake) /
              (maxStudentMistake - minStudentMistake)) *
            100
          ).toFixed(1)
          const slowStocCategorySkill = 100 - slowStocMistake
          slowStocStudentCategorySkill.push(slowStocCategorySkill)
          slowStocStudentMistake.push(slowStocMistake)
        })
      }

      student.skillCategory = slowStocStudentCategorySkill
      student.mistake = slowStocStudentMistake

      const equalArr = [
        "50",
        "50",
        "50",
        "50",
        "50",
        "50",
        "50",
        "50",
        "50",
        "50",
        "50",
        "50",
        "50",
        "50",
        "50",
      ]

      if (slowStocStudentMistake.length > 0) {
        studentCategoryList.push(slowStocStudentCategorySkill)
        studentMistakeList.push(slowStocStudentMistake)
      } else {
        studentCategoryList.push(equalArr)
        studentMistakeList.push(equalArr)
      }

      if (student.id == studentId) {
        studentMistakeData =
          slowStocStudentMistake.length > 0
            ? slowStocStudentMistake.map(Number)
            : equalArr.map(Number)
        studentCategoryData =
          slowStocStudentMistake.length > 0
            ? slowStocStudentCategorySkill.map(Number)
            : equalArr.map(Number)
      }
    })

    const N = 15
    let n = 0

    while (n < N) {
      let k = 0
      const K = studentMistakeList.length

      while (k < K) {
        allMistakeNumbers[n].push(studentMistakeList[k][n])
        allCategoryNumbers[n].push(studentCategoryList[k][n])
        k += 1
      }

      n += 1
    }

    allMistakeNumbers.forEach((e) => {
      const divider = e.length
      let sum = 0
      e.forEach((a) => (sum += Number(a)))
      const mean = (sum / divider).toFixed(1)
      averageMistakeData.push(mean)
      averageCategoryData.push((100 - mean).toFixed(1))
    })

    dashboardData.overview.mistakeCategory.class.mistake =
      averageMistakeData.map(Number)
    dashboardData.overview.mistakeCategory.class.safety =
      averageCategoryData.map(Number)
    dashboardData.overview.mistakeCategory.student.mistake = studentMistakeData
    dashboardData.overview.mistakeCategory.student.safety = studentCategoryData
  }

  prepareWords = (studentRawData) => {
    const inputSessions = studentRawData.inputSessionsByStudent.nodes
    const writtenWords = [0, 0, 0, 0, 0]
    const correctWords = [0, 0, 0, 0, 0]
    const firstAttemptWords = [0, 0, 0, 0, 0]
    const sessionsByCurrentWeek = []
    const sortedByDay = [[], [], [], [], []]

    inputSessions.forEach((word) => {
      const time = word.startTime
      const dateObj = new Date()
      dateObj.setTime(time)
      const year = dateObj.toLocaleString("default", {
        year: "numeric",
      })
      const month = dateObj.toLocaleString("default", {
        month: "2-digit",
      })
      const day = dateObj.toLocaleString("default", {
        day: "2-digit",
      })
      const date = year + "-" + month + "-" + day
      const now = moment()
      const dateToCompare = moment(date)

      const isThisWeek = now.isoWeek() == dateToCompare.isoWeek()
      if (isThisWeek) {
        sessionsByCurrentWeek.push(word)
      }
    })
    sessionsByCurrentWeek.forEach((word) => {
      const dateAndTime = new Date()
      dateAndTime.setTime(word.startTime)
      const weekDay = dateAndTime.getDay()
      if (weekDay >= 1 && weekDay <= 5) {
        sortedByDay[weekDay - 1].push(word)
      }
    })
    const DAYS = 5
    let index = 0
    while (index < DAYS) {
      const currentDayData = sortedByDay[index]
      if (currentDayData.length > 0) {
        currentDayData.forEach((data) => {
          if (data.input != "") {
            writtenWords[index] += 1
            if (data.correct) {
              correctWords[index] += 1
              if (data.attempt == 1) {
                firstAttemptWords[index] += 1
              }
            }
          }
        })
      }
      index += 1
    }
    dashboardData.overview.wordArea.writtenWords = writtenWords
    dashboardData.overview.wordArea.correctWords = correctWords
    dashboardData.overview.wordArea.firstAttemptWords = firstAttemptWords
  }

  prepareSpeed = (studentRawData) => {
    const inputSessions = studentRawData.inputSessionsByStudent.nodes
    const uiActivities = studentRawData.uiActivitiesByStudentId.nodes
    const sessionsByCurrentWeek = []
    const sortedByDay = [[], [], [], [], []]
    const wordsWithTimeByDay = [
      {
        wordsCount: 0,
        timeInMs: 0.0,
      },
      {
        wordsCount: 0,
        timeInMs: 0.0,
      },
      {
        wordsCount: 0,
        timeInMs: 0.0,
      },
      {
        wordsCount: 0,
        timeInMs: 0.0,
      },
      {
        wordsCount: 0,
        timeInMs: 0.0,
      },
    ]
    const wordSpeed = []
    const keyboardSpeed = [0, 0, 0, 0, 0]
    inputSessions.forEach((word) => {
      const time = word.startTime
      const dateObj = new Date()
      dateObj.setTime(time)
      const year = dateObj.toLocaleString("default", {
        year: "numeric",
      })
      const month = dateObj.toLocaleString("default", {
        month: "2-digit",
      })
      const day = dateObj.toLocaleString("default", {
        day: "2-digit",
      })
      const date = year + "-" + month + "-" + day
      const now = moment()
      const dateToCompare = moment(date)

      const isThisWeek = now.isoWeek() == dateToCompare.isoWeek()
      if (isThisWeek) {
        sessionsByCurrentWeek.push(word)
      }
    })
    sessionsByCurrentWeek.forEach((word) => {
      const dateAndTime = new Date()
      dateAndTime.setTime(word.startTime)
      const weekDay = dateAndTime.getDay()
      if (weekDay >= 1 && weekDay <= 5) {
        sortedByDay[weekDay - 1].push(word)
      }
    })

    const DAYS = 5
    let index = 0
    while (index < DAYS) {
      const currentDayData = sortedByDay[index]
      if (currentDayData.length > 0) {
        currentDayData.forEach((data) => {
          if (data.input != "") {
            wordsWithTimeByDay[index].wordsCount += 1
            wordsWithTimeByDay[index].timeInMs += data.durationMs
          }
        })
      }
      index += 1
    }

    let k = 0
    wordsWithTimeByDay.forEach((day) => {
      if (day.wordsCount > 0) {
        const msToMin = day.timeInMs / (1000 * 60)
        wordSpeed[k] = (day.wordsCount / msToMin).toFixed(1)
      } else {
        wordSpeed[k] = 0
      }
      k += 1
    })

    const filteredUiActivities = uiActivities.filter(
      (act) =>
        act.button &&
        act.page === "/exercise" &&
        act.button.startsWith("keyboard")
    )

    const uiActivitiesByCurrentWeek = []
    const uiSortedByDay = [[], [], [], [], []]

    filteredUiActivities.forEach((activity) => {
      const time = activity.timestamp
      const dateObj = new Date()
      dateObj.setTime(time)
      const year = dateObj.toLocaleString("default", {
        year: "numeric",
      })
      const month = dateObj.toLocaleString("default", {
        month: "2-digit",
      })
      const day = dateObj.toLocaleString("default", {
        day: "2-digit",
      })
      const date = year + "-" + month + "-" + day
      const now = moment()
      const dateToCompare = moment(date)

      const isThisWeek = now.isoWeek() == dateToCompare.isoWeek()
      if (isThisWeek) {
        uiActivitiesByCurrentWeek.push(activity)
      }
    })

    uiActivitiesByCurrentWeek.forEach((activity) => {
      const dateAndTime = new Date()
      dateAndTime.setTime(activity.timestamp)
      const weekDay = dateAndTime.getDay()
      if (weekDay >= 1 && weekDay <= 5) {
        uiSortedByDay[weekDay - 1].push(activity)
      }
    })

    const sortedUiActivities = [[], [], [], [], []]
    const differentSessionIds = []

    uiSortedByDay.forEach((day) => {
      day.forEach((activity) => {
        if (!differentSessionIds.includes(activity.sessionId)) {
          differentSessionIds.push(activity.sessionId)
        }
      })
    })

    let idx = 0
    differentSessionIds.forEach((id) => {
      uiSortedByDay.forEach((day) => {
        day.forEach((activity) => {
          if (activity.sessionId == id) {
            sortedUiActivities[idx].push(activity)
          }
        })
        idx += 1
      })
      idx = 0
    })

    const sortedBySessionId = []

    sortedUiActivities.forEach((day) => {
      let dayArr = []
      differentSessionIds.forEach((id) => {
        let sessionArr = []
        day.forEach((activity) => {
          const sessionId = activity.sessionId
          if (sessionId == id) {
            sessionArr.push(activity.timestamp)
          }
        })
        if (sessionArr.length > 0) {
          dayArr.push(sessionArr)
        }
        sessionArr = []
      })
      sortedBySessionId.push(dayArr)
      dayArr = []
    })

    const sortedTimestamps = sortedBySessionId

    sortedTimestamps.forEach((day) => {
      day.forEach((session) => {
        session.sort().reverse()
      })
    })

    const timeDiffArr = []
    sortedTimestamps.forEach((day) => {
      const dayArr = []
      day.forEach((session) => {
        let k = 0
        while (k < session.length - 1) {
          const startDate = new Date()
          startDate.setTime(session[k + 1])
          const endDate = new Date()
          endDate.setTime(session[k])
          const milSec = endDate.getTime() - startDate.getTime()
          dayArr.push(milSec)
          k += 1
        }
      })
      timeDiffArr.push(dayArr)
    })

    let n = 0

    timeDiffArr.forEach((day) => {
      const clickCounter = day.length
      let dailySum = 0
      day.forEach((dif) => {
        dailySum += dif
      })
      if (clickCounter > 0) {
        keyboardSpeed[n] = (clickCounter / (dailySum / (1000 * 60))).toFixed(1)
      }
      n += 1
    })

    dashboardData.overview.speed.word = wordSpeed
    dashboardData.overview.speed.keyboard = keyboardSpeed
  }

  setDashboardData = (studentName, className) => {
    const achievement = this.state.class.dailyTargetAchieved
    const count = this.state.class.studentsCount
    const real = this.state.student.dailyTargetData.real
    const target = this.state.student.dailyTargetData.target

    dashboardData.general.student = studentName
    dashboardData.general.class = className
    dashboardData.overview.dailyTarget.class.studentsCount = count
    dashboardData.overview.dailyTarget.class.targetAchievement = achievement
    dashboardData.overview.dailyTarget.student.writtenWords = real
    dashboardData.overview.dailyTarget.student.targetWords = target
  }

  render() {
    const windowHeight = this.state.window.height

    return (
      <div>
        <Seo title="Dashboard" />
        <Navigation>
          <BackButton
            as={Link}
            to={`/teacher/dashboard?classId=${
              isBrowser
                ? new URLSearchParams(window.location.search).get("classId")
                : null
            }`}
            alt="Back to profile"
          >
            <ArrowIcon />
          </BackButton>
        </Navigation>
        <Content height={windowHeight}>
          <DiagramsLayout>
            <DiagramsTabs data={dashboardData} />
          </DiagramsLayout>
        </Content>
      </div>
    )
  }
}
// component-end

// PropTypes
TeacherDashboardDiagramsPage.propTypes = {
  window: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
  }).isRequired,
}

export default TeacherDashboardDiagramsPage
