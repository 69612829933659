import React from "react"
import ReactEcharts from "echarts-for-react"
import { theme } from "../../styles/theme"
function RadarChartDisplay({ labels, data }) {
  const studMaxVal = Math.max.apply(Math, data.student)
  const classMaxVal = Math.max.apply(Math, data.class)
  const option = {
    textStyle: {
      color: "#ffffff",
    },
    color: [theme.colors.feedback.good.main, theme.colors.feedback.bad.main],
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
        label: {
          backgroundColor: "#6a7985",
        },
      },
    },
    toolbox: {
      feature: {
        saveAsImage: {
          title: "Download",
          name: labels.student + "s_taetigkeiten",
          iconStyle: { color: "#ffffff", borderColor: "#ffffff" },
          backgroundColor: theme.colors.primary.shade,
        },
      },
    },
    legend: {
      data: [labels.student + " / sec", "Klasse " + labels.class + " / sec"],
      textStyle: {
        color: "#ffffff",
      },
      bottom: "0%",
    },
    radar: {
      // shape: 'circle',
      indicator: [
        {
          name: "Basisaufgabe",
          max: studMaxVal > classMaxVal ? studMaxVal : classMaxVal,
        },
        {
          name: "Spiele",
          max: studMaxVal > classMaxVal ? studMaxVal : classMaxVal,
        },
        {
          name: "Heimatplanet",
          max: studMaxVal > classMaxVal ? studMaxVal : classMaxVal,
        },
        {
          name: "Dashboard",
          max: studMaxVal > classMaxVal ? studMaxVal : classMaxVal,
        },
        {
          name: "Sonstiges",
          max: studMaxVal > classMaxVal ? studMaxVal : classMaxVal,
        },
      ],
    },
    series: [
      {
        name: "Schüler vs Klasse",
        type: "radar",
        areaStyle: {},
        tooltip: { trigger: "item" },
        data: [
          {
            symbol: "emptyRoundRect",
            symbolSize: 6,
            value: data.student,
            name: labels.student + " / sec",
          },
          {
            symbol: "emptyTriangle",
            symbolSize: 6,
            value: data.class,
            name: "Klasse " + labels.class + " / sec",
          },
        ],
      },
    ],
  }
  return <ReactEcharts option={option} />
}
export default RadarChartDisplay
