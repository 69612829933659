import * as React from "react"
import { Box, Tab, Tabs, Typography } from "@mui/material"
import { useState } from "react"
import { theme } from "../../styles/theme"
import styled from "styled-components"
import BarChart from "../BarChart"
import LineChart from "../LineChart"
import AreaChart from "../AreaChart"
import RadarChart from "../RadarChart"
import WordProgressBar from "../WordProgressBar"
import Tali from "../Tali"
import BluePlanet from "../../images/planets/blue.png"
import Rocket from "../../images/rocket.png"

// Layout
export const DiagramsTabsWrapper = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(
      0,
      1fr
    );
  gap: 0px 0px;
  grid-template-areas:
    "RocketRow"
    "."
    "."
    "RadarRow";
  text-align: center;
  .RocketRow {
    display: grid;
    grid-template-columns: 0.33fr 0.67fr;
    grid-template-rows: minmax(0, 1fr);
    gap: 0px 0px;
    grid-template-areas: ". .";
    grid-area: RocketRow;

    #rocket {
      height: 10%;
      width: 10%;
      display: block;
      margin-left: auto;
      margin-right: auto;
      animation: shake 1.75s;
      animation-iteration-count: infinite;
    }
    @keyframes shake {
      0% {
        transform: translate(1px, 1px) rotate(0deg);
      }
      10% {
        transform: translate(-1px, -2px) rotate(-1deg);
      }
      20% {
        transform: translate(-3px, 0px) rotate(1deg);
      }
      30% {
        transform: translate(3px, 2px) rotate(0deg);
      }
      40% {
        transform: translate(1px, -1px) rotate(1deg);
      }
      50% {
        transform: translate(-1px, 2px) rotate(-1deg);
      }
      60% {
        transform: translate(-3px, 1px) rotate(0deg);
      }
      70% {
        transform: translate(3px, 1px) rotate(-1deg);
      }
      80% {
        transform: translate(-1px, -1px) rotate(1deg);
      }
      90% {
        transform: translate(1px, 2px) rotate(0deg);
      }
      100% {
        transform: translate(1px, -2px) rotate(-1deg);
      }
    }
  }

  .RadarRow {
    display: grid;
    grid-template-columns: 0.5fr 0.5fr;
    grid-template-rows: minmax(0, 1fr);
    gap: 0px 0px;
    grid-template-areas: ". .";
    grid-area: RadarRow;
  }
`
// Layout-end

function getRocketCounter(number, achievement) {
  let rocketCounter = ""

  const start = number
  const end = number - achievement

  let i = start

  while (i >= end) {
    rocketCounter += i.toString() + "... "
    i -= 1
  }

  return rocketCounter
}

function setTaliText(data) {
  const isWeekend = data.general.isWeekend
  const className = data.general.class
  const studentName = data.general.student
  const writtenWords = data.overview.dailyTarget.student.writtenWords
  const targetWords = data.overview.dailyTarget.student.targetWords
  const targetAchievement = data.overview.dailyTarget.class.targetAchievement
  const studentsCount = data.overview.dailyTarget.class.studentsCount

  if (!isWeekend) {
    const rocket = Rocket
    return (
      <div>
        <h2>{getRocketCounter(studentsCount, targetAchievement)}</h2>
        <img src={rocket} id="rocket" />
        <h3>
          {targetAchievement}{" "}
          {targetAchievement == 1 ? "Schüler:in" : "Schüler:innen"} der Klasse{" "}
          {className} {targetAchievement == 1 ? "kann" : "können"} bisher ins
          Weltall fliegen!
        </h3>
      </div>
    )
  } else {
    return (
      <div>
        <h2>Hoch die Hände, Wochenende!</h2>
        <h2>🥳🥳🥳</h2>
      </div>
    )
  }
}
function DiagramsTabsDisplay({ data }) {
  console.log("Tabs-view: ", data)
  const [tabIndex, setTabIndex] = useState(0)

  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex)
  }

  React.useEffect(() => {
    console.log("tab index:", tabIndex)
  }, [tabIndex])

  const blue = BluePlanet
  const rocket = Rocket

  return (
    <Box>
      <Box>
        <Tabs value={tabIndex} onChange={handleTabChange}>
          <Tab
            label="Übersicht"
            sx={{
              fontSize: theme.fontSizes.regular,
              fontFamily: "Grundschrift Bold",
              color: theme.colors.light,
            }}
          />
          <Tab
            label="Leistung"
            sx={{
              fontSize: theme.fontSizes.regular,
              fontFamily: "Grundschrift Bold",
              color: theme.colors.light,
            }}
          />
          <Tab
            label="Motivation"
            sx={{
              fontSize: theme.fontSizes.regular,
              fontFamily: "Grundschrift Bold",
              color: theme.colors.light,
            }}
          />
          <Tab
            label="Konzentration"
            sx={{
              fontSize: theme.fontSizes.regular,
              fontFamily: "Grundschrift Bold",
              color: theme.colors.light,
            }}
          />
        </Tabs>
      </Box>
      <Box
        sx={{
          padding: 2,
          fontSize: theme.fontSizes.regular,
          fontFamily: "Grundschrift Bold",
          color: theme.colors.light,
        }}
      >
        {tabIndex === 0 && (
          <Box>
            <DiagramsTabsWrapper>
              <section className="RocketRow">
                <article>
                  <br />
                  <h2>{data.general.student}s Tagesziel</h2>
                  <br />
                  <p>
                    {!data.general.isWeekend
                      ? data.overview.dailyTarget.student.writtenWords
                      : 0}{" "}
                    von{" "}
                    {!data.general.isWeekend
                      ? data.overview.dailyTarget.student.targetWords
                      : 0}{" "}
                    Wörtern
                  </p>
                  <WordProgressBar
                    targetLearnedWords={
                      !data.general.isWeekend
                        ? data.overview.dailyTarget.student.targetWords
                        : 1
                    }
                    realLearnedWords={
                      !data.general.isWeekend
                        ? data.overview.dailyTarget.student.writtenWords
                        : 2.25
                    }
                    progressSizeRatio={33}
                    planet={blue}
                  />
                </article>
                <Tali
                  mood="happy"
                  size={1}
                  position="top"
                  float={false}
                  messageStyle="glow"
                >
                  {setTaliText(data)}
                </Tali>
              </section>
              <section>
                <article>
                  <br />
                  <h2>{data.general.student}s Wortebene</h2>
                  <br />
                  <AreaChart
                    labels={data.general}
                    data={data.overview.wordArea}
                  />
                </article>
              </section>
              <section>
                <article>
                  <br />
                  <h2>{data.general.student}s Lupenstellen</h2>
                  <br />
                  <BarChart
                    labels={data.general}
                    data={data.overview.mistakeCategory}
                  />
                </article>
              </section>
              <section className="RadarRow">
                <article>
                  <br />
                  <h2>{data.general.student}s Geschwindigkeiten</h2>
                  <br />
                  <LineChart labels={data.general} data={data.overview.speed} />
                </article>
                <article>
                  <br />
                  <h2>{data.general.student}s Tätigkeiten</h2>
                  <br />
                  <RadarChart
                    labels={data.general}
                    data={data.overview.tasks}
                  />
                </article>
              </section>
            </DiagramsTabsWrapper>
          </Box>
        )}
        {tabIndex === 1 && (
          <Box>
            <Tali
              mood="curious"
              size={4}
              position="bottom"
              float={false}
              messageStyle="glow"
            >
              <h1>Hab noch ein klein wenig Geduld! ⏳</h1>
            </Tali>
          </Box>
        )}
        {tabIndex === 2 && (
          <Box>
            <Tali
              mood="curious"
              size={4}
              position="bottom"
              float={false}
              messageStyle="glow"
            >
              <h1>Hab noch ein klein wenig Geduld! ⏳</h1>
            </Tali>
          </Box>
        )}
        {tabIndex === 3 && (
          <Box>
            <Tali
              mood="curious"
              size={4}
              position="bottom"
              float={false}
              messageStyle="glow"
            >
              <h1>Hab noch ein klein wenig Geduld! ⏳</h1>
            </Tali>
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default DiagramsTabsDisplay
