// Basic libraries
import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"

// Specific libraries
import { CircularProgressbarWithChildren } from "react-circular-progressbar"
import Sparkle from "react-sparkle"

// Styling
import {
  WordProgressWrapper,
  InnerProgressWrapper,
} from "./WordProgressBarStyling"
import { theme } from "../../styles/theme"

// component
const WordProgressBarDisplay = ({
  targetLearnedWords = 10,
  realLearnedWords = 0,
  progressSizeRatio,
  progressColor = theme.colors.feedback.good.main,
  bonusProgressColor = theme.colors.accent.main,
  planet,
  sparkleColor = theme.colors.cta.main,
  isHidden = false,
}) => {
  const [percentage, setPercentage] = useState(0)
  const [bonusPercentage, setBonusPercentage] = useState(0)
  const [minSizeSparkle, setMinSizeSparkle] = useState(0)
  const [maxSizeSparkle, setMaxSizeSparkle] = useState(0)
  const [shouldSparkleUpdate, setShouldSparkleUpdate] = useState(false)
  const fadeOutSpeedSparkle = 85
  const overflowPxSparkle = 0

  useEffect(() => {
    setShouldSparkleUpdate(true)

    const tempPercentage =
      realLearnedWords > targetLearnedWords
        ? 100
        : (realLearnedWords / targetLearnedWords) * 100
    const tempBonusPercentage =
      tempPercentage < 100
        ? 0
        : (realLearnedWords / targetLearnedWords - 1) * 100

    setPercentage(tempPercentage)
    setBonusPercentage(tempBonusPercentage)
  }, [targetLearnedWords, realLearnedWords])

  useEffect(() => {
    setShouldSparkleUpdate(false)
    console.log("bonusPercentage: ", bonusPercentage)
  }, [bonusPercentage])

  useEffect(() => {
    const tempMinSizeSparkle = progressSizeRatio / 8
    setMinSizeSparkle(tempMinSizeSparkle)
    setMaxSizeSparkle(4 * tempMinSizeSparkle)
  }, [progressSizeRatio])

  return (
    <WordProgressWrapper
      size={progressSizeRatio}
      bonProgColor={bonusProgressColor}
      isHidden={isHidden}
    >
      <CircularProgressbarWithChildren
        value={bonusPercentage}
        circleRatio={1.0}
      >
        <InnerProgressWrapper
          size={0.9 * progressSizeRatio}
          progColor={progressColor}
        >
          <CircularProgressbarWithChildren
            value={percentage}
            strokeWidth={27.5}
            circleRatio={1.0}
          >
            <img src={planet} alt="Planet" />
          </CircularProgressbarWithChildren>
        </InnerProgressWrapper>
        {!shouldSparkleUpdate && (
          <Sparkle
            color={sparkleColor}
            minsize={minSizeSparkle}
            maxSize={maxSizeSparkle}
            fadeOutSpeed={fadeOutSpeedSparkle}
            overflowPx={overflowPxSparkle}
            count={bonusPercentage}
          />
        )}
      </CircularProgressbarWithChildren>
    </WordProgressWrapper>
  )
}
// component-end

WordProgressBarDisplay.propTypes = {
  targetLearnedWords: PropTypes.number.isRequired,
  realLearnedWords: PropTypes.number.isRequired,
  progressSizeRatio: PropTypes.number.isRequired,
  progressColor: PropTypes.string,
  bonusProgressColor: PropTypes.string,
  planet: PropTypes.node.isRequired,
  sparkleColor: PropTypes.string,
  isHidden: PropTypes.bool,
}

export default WordProgressBarDisplay
