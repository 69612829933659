import React from "react"
import ReactEcharts from "echarts-for-react"
import { theme } from "../../styles/theme"

function AreaChartDisplay({ labels, data }) {
  const option = {
    textStyle: {
      color: "#ffffff",
    },
    color: [
      theme.colors.feedback.bad.shade,
      theme.colors.feedback.warning.main,
      theme.colors.feedback.good.main,
    ],
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
        label: {
          backgroundColor: "#6a7985",
        },
      },
    },
    legend: {
      textStyle: {
        color: "#ffffff",
      },
      data: [
        "Anzahl geschr. Wörter",
        "Anzahl korrekt geschr. Wörter",
        "Anzahl auf 1. Versuch korrekt geschr. Wörter",
      ],
    },
    toolbox: {
      feature: {
        saveAsImage: {
          title: "Download",
          name: labels.student + "s_wortebene",
          iconStyle: { color: "#ffffff", borderColor: "#ffffff" },
          backgroundColor: theme.colors.primary.shade,
        },
      },
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
    },
    xAxis: [
      {
        nameTextStyle: { fontSize: 16 },
        type: "category",
        boundaryGap: false,
        data: ["Mo", "Di", "Mi", "Do", "Fr"],
        splitLine: {
          show: false,
        },
      },
    ],
    yAxis: [
      {
        type: "value",
        minInterval: 1,
        splitLine: {
          show: false,
        },
      },
    ],
    series: [
      {
        symbol: "emptyTriangle",
        symbolSize: 12,
        smooth: true,
        name: "Anzahl geschr. Wörter",
        type: "line",
        areaStyle: {},
        emphasis: {
          focus: "series",
        },
        data: data.writtenWords,
      },
      {
        symbol: "emptyRoundRect",
        symbolSize: 12,
        smooth: true,
        name: "Anzahl korrekt geschr. Wörter",
        type: "line",
        areaStyle: {},
        emphasis: {
          focus: "series",
        },
        data: data.correctWords,
      },
      {
        symbol: "emptyCircle",
        symbolSize: 12,
        smooth: true,
        name: "Anzahl auf 1. Versuch korrekt geschr. Wörter",
        type: "line",
        areaStyle: {},
        emphasis: {
          focus: "series",
        },
        data: data.firstAttemptWords,
      },
    ],
  }
  return <ReactEcharts option={option} />
}

export default AreaChartDisplay
