import React from "react"
import ReactEcharts from "echarts-for-react"
import { theme } from "../../styles/theme"

function BarChartDisplay({ labels, data }) {
  console.log(data)
  const option = {
    textStyle: {
      color: "#ffffff",
    },
    color: [
      theme.colors.feedback.good.shade,
      theme.colors.feedback.bad.shade,
      theme.colors.feedback.warning.shade,
      theme.colors.cta.shade,
    ],
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
        label: {
          backgroundColor: "#6a7985",
        },
      },
    },
    legend: {
      textStyle: {
        color: "#ffffff",
      },
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
    },
    toolbox: {
      feature: {
        saveAsImage: {
          title: "Download",
          name: labels.student + "s_lupenstellen",
          iconStyle: { color: "#ffffff", borderColor: "#ffffff" },
          backgroundColor: theme.colors.primary.shade,
        },
      },
    },
    xAxis: {
      type: "category",
      axisLabel: {
        show: false,
      },
      data: [
        "Basale Phonem-Graphem-Korrespondenzen (ohne Zusatzregel)",
        "Basale Phonem-Graphem-Korrespondenzen (mit Zusatzregel)",
        "Reduktionssilbe",
        "Doppelkonsonantschreibung (Schärfung)",
        "Intervokalisches h (~silbeninitiales h)",
        "Dehnungs-h",
        "ß / Scharf-S / Eszett",
        "Umlautschreibung (ä-e, äu-eu)",
        "Auslautschreibung (b-p, d-t, g-k, ts, ds, ks, gs, vok. R, ig-ich)",
        "Morphemanschluss",
        "Zusammenschreibung",
        "Großschreibung",
        "Merkelement",
        "Übergeneralisierungen",
        "Restkategorie",
      ],
      splitLine: {
        show: false,
      },
    },
    yAxis: {
      type: "value",
      name: "%",
      nameLocation: "end",

      splitLine: {
        show: false,
      },
    },
    series: [
      {
        name: `Sicherheit (${labels.student})`,
        type: "bar",
        stack: "Student",
        emphasis: {
          focus: "series",
        },
        data: data.student.safety,
        datasetIndex: 1,
      },
      {
        name: `Fehleranfälligkeit (${labels.student})`,
        type: "bar",
        stack: "Student",
        emphasis: {
          focus: "series",
        },
        data: data.student.mistake,
        datasetIndex: 2,
      },
      {
        name: `Sicherheit (Klasse ${labels.class})`,
        type: "bar",
        stack: "Class",
        emphasis: {
          focus: "series",
        },
        data: data.class.safety,
        datasetIndex: 3,
      },
      {
        name: `Fehleranfälligkeit (Klasse ${labels.class})`,
        type: "bar",
        stack: "Class",
        emphasis: {
          focus: "series",
        },
        data: data.class.mistake,
        datasetIndex: 4,
      },
    ],
  }
  return <ReactEcharts option={option} />
}

export default BarChartDisplay
