// Styling libraries
import styled from "styled-components"
import { theme } from "../../styles/theme"
import "react-circular-progressbar/dist/styles.css"

// Layout
export const WordProgressWrapper = styled.div`
  width: ${(props) => props.size}vmin;
  height: ${(props) => props.size}vmin;
  z-index: 2;
  margin-left: auto;
  margin-right: auto;
  opacity: ${(props) => (props.isHidden ? 0.3 : 1.0)};
  .CircularProgressbar .CircularProgressbar-path {
    stroke: ${(props) => props.bonProgColor};
    stroke-linecap: round;
  }
  .CircularProgressbar .CircularProgressbar-trail {
    stroke: ${theme.colors.primary.shade};
    stroke-linecap: round;
  }
  .CircularProgressbar .CircularProgressbar-background {
    fill: ${theme.colors.primary.shade};
  }
  .CircularProgressbar .CircularProgressbar-text {
    fill: ${theme.colors.primary.main};
    font-size: ${theme.fontSizes.small};
  }
`

export const InnerProgressWrapper = styled(WordProgressWrapper)`
  z-index: 1;
  .CircularProgressbar .CircularProgressbar-path {
    stroke: ${(props) => props.progColor};
  }
  .CircularProgressbar .CircularProgressbar-trail {
    stroke: ${theme.colors.primary.main};
  }
  img {
    width: ${(props) => 0.455 * props.size}vmin;
    height: ${(props) => 0.455 * props.size}vmin;
  }
`
// Layout-end
