import React from "react"
import ReactEcharts from "echarts-for-react"
import { theme } from "../../styles/theme"

function LineChartDisplay({ labels, data }) {
  const options = {
    textStyle: {
      color: "#ffffff",
    },
    color: [theme.colors.feedback.good.main, theme.colors.feedback.bad.shade],
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
        label: {
          backgroundColor: "#6a7985",
        },
      },
    },
    toolbox: {
      feature: {
        saveAsImage: {
          title: "Download",
          name: labels.student + "s_geschwindigkeiten",
          iconStyle: { color: "#ffffff", borderColor: "#ffffff" },
          backgroundColor: theme.colors.primary.shade,
        },
      },
    },
    legend: {
      textStyle: {
        color: "#ffffff",
      },
      data: ["Wörter / min", "Tastaturanschläge / min"],
    },

    xAxis: {
      type: "category",
      data: ["Mo", "Di", "Mi", "Do", "Fr"],
      splitLine: {
        show: false,
      },
    },
    yAxis: {
      type: "value",
    },
    series: [
      {
        name: "Wörter / min",
        data: data.word,
        symbol: "emptyRoundRect",
        symbolSize: 9,
        type: "line",
        smooth: true,
      },
      {
        name: "Tastaturanschläge / min",
        data: data.keyboard,
        symbol: "emptyTriangle",
        symbolSize: 9,
        type: "line",
        smooth: true,
      },
    ],
  }
  return <ReactEcharts option={options} />
}

export default LineChartDisplay
